/* global window */

import React from 'react'
import Button from '@material-ui/core/Button'

import { useCartContext } from '../../context/cart'
import stripe from '../../services/stripeClient'

const CheckoutButton = () => {
  const [state,] = useCartContext()

  const handleClick = async () => {
    const Stripe = await stripe()

    Stripe.redirectToCheckout({
      // lineItems:  https://stripe.com/docs/js/checkout/redirect_to_checkout
      lineItems: state.cartList.reduce((acc, item) => {
        acc.push({
          price: item.priceId,
          quantity: item.quantity
        })
        return acc
      }, []),
      mode: 'payment',
      successUrl: `${window.location.origin}/success`,
      cancelUrl: `${window.location.origin}/cart`,
      shippingAddressCollection: {
        allowedCountries: ['US'],
      },
    })
  }

  return (
    <Button variant="contained" color="primary" fullWidth onClick={handleClick}>
      CHECKOUT
    </Button>
  )
}

export default CheckoutButton
