import React from 'react';
import withWidth from '@material-ui/core/withWidth';

import { compose, isMobileWidth, Spacer } from '../utils'
import {
  Layout,
  SEO
} from 'components';
import Cart from 'containers/cart';

const CartPage = ({width}) => {
  const isMobile = isMobileWidth(width)
  return (
    <Layout>
      <SEO title='Home' />
      <Cart />
      <Spacer mobile={0} desktop={48} isMobile={isMobile} />
    </Layout>
  )
};

export default compose(
  withWidth(),
)(CartPage);
