import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { ThemeProvider } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';

import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import withWidth from '@material-ui/core/withWidth';
import { compose, isMobileWidth } from 'utils';
import { useCartContext } from '../../../../context/cart';

const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 0, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 20, // rem
      md: 30, // rem
      lg: 40, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 25, // em
      md: 30, // em
      lg: 40, // em
    },
  },
};

const COLUMNS = {
  image: {
    lg: 3,
    md: 3,
    sm: 5,
    xs: 5,
  },
  list: {
    lg: 3,
    md: 3,
    sm: 7,
    xs: 7,
  },
  selector: {
    lg: 6,
    md: 6,
    sm: 12,
    xs: 12,
  },
};

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000000',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#000000',
      boxShadow: '0 0 0 0.2rem rgb(255, 255, 255)',
    },
  },
}))(InputBase);

const ImageContainer = styled.div`
  width: 90px;
  height: 125px;
`;

const Wrapper = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  box-shadow: 5px 5px 8px #888888;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${'' /* margin: 0 0 0 15px; */}
  width: 160px;
  height: 125px;
`;

const SelectWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Divider = styled.hr`
  height: 3px;
  background-color: #000000;
  margin: 15px 0;
`;

const ProductInfo = ({ width }) => {
  const [state, dispatch] = useCartContext();

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { absolutePath: { regex: "/images/covers/" } }) {
        nodes {
          relativePath
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            # fixed default is 400px
            fixed(width: 90, height: 125) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  `);

  // maps relative path to fixed data. ex) {"covers/fedUp.jpg": "data:image/jpeg;base64,/9j/ ..."}
  const imageMap = useMemo(
    () =>
      data.allFile.nodes.reduce((acc, node) => {
        acc[node.relativePath] = node.childImageSharp.fixed;
        return acc;
      }, {}),
    [data]
  );

  const handleRemoveItem = productId => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { productId } });
  };

  const handleSelectChange = e => {
    dispatch({
      type: 'SET_QUANTITY',
      payload: { productId: e.target.name, quantity: e.target.value },
    });
  };

  const isMobile = isMobileWidth(width);

  return (
    <Wrapper>
      <ThemeProvider theme={theme}>
        <Grid>
          {state.cartList.map((item, index) => {
            return (
              <div key={item.priceId}>
                <Row>
                  <Col {...COLUMNS.image}>
                    <ImageContainer>
                      <Img fixed={imageMap[item.imageRelativePath]} />
                    </ImageContainer>
                  </Col>

                  <Col {...COLUMNS.list}>
                    <List>
                      <div>
                        <Typography variant='h6' color='textPrimary'>
                          {item.name}
                        </Typography>
                      </div>

                      <div>Author: {item.author}</div>

                      <div>{item.type}</div>

                      <div>
                        <div>
                          <Button
                            id='fed'
                            color='primary'
                            startIcon={<DeleteIcon color='primary' />}
                            size='small'
                            variant='text'
                            style={{ padding: '0px' }}
                            onClick={() => handleRemoveItem(item.productId)}>
                            Remove
                          </Button>
                        </div>
                      </div>
                    </List>
                  </Col>

                  <Col {...COLUMNS.selector}>
                    {isMobile ? (
                      <Select
                        style={{ width: '100%', marginTop: '5px' }}
                        labelId='demo-customized-select-label'
                        id='demo-customized-select'
                        value={item.quantity}
                        // defaultValue={item.quantity}
                        name={item.productId}
                        onChange={handleSelectChange}
                        input={<BootstrapInput />}
                        // autoWidth={true}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                      </Select>
                    ) : (
                      <SelectWrapper>
                        <Select
                          labelId='demo-customized-select-label'
                          id='demo-customized-select'
                          // defaultValue={item.quantity}
                          name={item.productId}
                          value={item.quantity}
                          onChange={handleSelectChange}
                          input={<BootstrapInput />}
                          autoWidth={true}>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                          <MenuItem value={9}>9</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                        </Select>
                      </SelectWrapper>
                    )}
                  </Col>
                </Row>
                {index === state.cartList.length - 1 ? null : <Divider />}
              </div>
            );
          })}
        </Grid>
      </ThemeProvider>
    </Wrapper>
  );
};

export default compose(withWidth())(ProductInfo);
