import React from 'react';
import { ThemeProvider } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import withWidth from '@material-ui/core/withWidth';

import { compose, isMobileWidth } from 'utils';
import { Animated } from 'components';
import content from './content';
import Checkout from './components/Checkout';
import ProductInfo from './components/ProductInfo';
import { Spacer } from 'utils';
import { useCartContext } from '../../context/cart';


const theme = {
  flexboxgrid: {
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 0, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 40, // rem
      md: 50, // rem
      lg: 60  // rem
    },
    breakpoints: {
      xs: 0,  // em
      sm: 45, // em
      md: 53, // em
      lg: 63  // em
    }
  }
}

const COLUMNS = {
  main: {
    lg: 6,
    md: 6,
    sm: 12,
    xs: 12
  }
}

const { title } = content;

const Cart = ({ width }) => {
  const [state,] = useCartContext()

  const isMobile = isMobileWidth(width)

  return (
    <>
      {/* <Spacer mobile={10} desktop={0} isMobile={isMobile}/> */}

      <Fade in timeout={1000}>
        <Typography variant='h1' color='textPrimary'>
          <Animated>{title}</Animated>
        </Typography>
      </Fade>

      <Spacer mobile={10} desktop={8} isMobile={isMobile}/>

      <ThemeProvider theme={theme}>
        <Grid >
          {
            state.cartList.length ?
              <Row>
                <Col  {...COLUMNS.main}>
                  <ProductInfo />
                </Col>

                {
                  isMobile ?
                  <Col  {...COLUMNS.main}>
                    <Spacer mobile={13} desktop={0} isMobile={isMobile}/>
                  </Col>: null
                }

                <Col  {...COLUMNS.main}>
                  <Checkout />
                </Col>
              </Row>
              : <Typography variant='h6' color='textPrimary'>
                Your Shopping Cart is empty.
              </Typography>
          }
        </Grid>
      </ThemeProvider>
    </>
  )
};

export default compose(
  withWidth(),
)(Cart);
