import React, { useMemo } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { useCartContext } from '../../../../context/cart';
import PriceObject from '../../../../classes/PriceObject';
import StripeCheckoutButton from '../../../../components/stripeCheckoutButton'


const Wrapper = styled.div`
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 5px 5px 8px #888888;
  max-height: 270px;
  width: 100%
`

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  ${'' /* justify-content: space-between; */}
  padding: 15px;
`

const HumanTotal = styled.div`
  font-weight: bold;
`


const Checkout = () => {
  const [state,] = useCartContext()
  const priceObject = useMemo(() => new PriceObject(state.cartList), [state])

  return (
    <Wrapper>

      <List>

        <Typography variant='h6' color='textPrimary'>
          Order Summary
        </Typography>

        <ListItem>
          <div>
            {`Items (${priceObject.itemQuantity})`}
          </div>
          <div>
            {priceObject.humanItemTotal}
          </div>
        </ListItem>

        <ListItem>
          <div>
            Shipping
          </div>
          <div>
            {priceObject.humanShipping}
          </div>
        </ListItem>

        <ListItem>
          <div>
            Estimated Tax
          </div>
          <div>
            {priceObject.humanTax}
          </div>
        </ListItem>

        <ListItem>
          <Typography variant='h6' color='textPrimary'>
            Order Total
          </Typography>
          <HumanTotal>
            {priceObject.humanTotal}
          </HumanTotal>
        </ListItem>

      </List>

      <StripeCheckoutButton />

    </Wrapper>
  )
}

export default Checkout
